import React, { Component } from 'react';
import LoadingOverlay from './LoadingOverlay';
// Three Scene building
import {
  CreateChrists,
  SetSceneDimensions
} from "../scene/SceneBasic";
// Img
import christSrc from '../images/christ.png';

let activeEnv = process.env.ACTIVE_ENV || process.env.NODE_ENV || "development";

export default class SceneComponent extends Component {
  constructor(props) {
    super(props);
    this.scrollTop = 0;
    this.autoMove = true;
    this.cameraInitial = {
      x: 0,
      y: 0,
      z: 200
    };
    this.keys = {
      up: false,
      down: false
    };
    this.state = {
      loaded: false
    };
    // this.clock = new THREE.Clock()
  }

  componentDidMount = () => {

    this.loadThree()
      .then(() => {
        this.setupRenderer();
        this.setupScene();
        this.setupCamera();
        this.setupLights();
        this.setupFog();
        this.buildScene()
          .then(() => {
            this.renderer.render(this.scene, this.camera);
            this.setupListeners();
            this.animate();
            this.setState({
              loaded: true
            });
          });
      });
  }

  loadThree = () => {
    const loadjs = require('loadjs');
    const threePath = '/three.101.min.js';
    return new Promise(function(resolve, reject) {
      loadjs([threePath], 'three');
      loadjs.ready('three', {
        success: resolve,
        error: reject
      });
    });
  }

  setupCamera = () => {
    let { innerWidth: width, innerHeight: height } = window;
    this.camera = new THREE.PerspectiveCamera(
      45,
      width / height,
      1,
      (this.cameraInitial.z * 3) + 1
    );
    this.camera.position.set(
      this.cameraInitial.x,
      this.cameraInitial.y,
      this.cameraInitial.z
    );
  }

  setupFog = () => {
    this.scene.fog = new THREE.Fog(0x000000, 1, (this.cameraInitial.z * 4) + 1);
  }

  setupRenderer = () => {
    let { innerWidth: width, innerHeight: height } = window;
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      preserveDrawingBuffer: false,
      powerPreference: 'high-performance'
    });

    // const pixelRatio = Math.max(2, window.devicePixelRatio);
    this.renderer.setPixelRatio(2);
    this.renderer.setSize(width, height);
    // Setup colors
    this.renderer.gammaFactor = 2.2;
    this.renderer.gammaOutPut = true;
  }
  
  setupListeners = () => {
    // Setup resize events
    window.addEventListener('resize', () => this._resize());
    window.addEventListener('orientationchange', () => this._resize());
    // Setup keyboard events
    this._listenArrowKeys();

  }

  setupScene = () => {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x000000);
  }

  buildScene = () => {
    return new Promise((resolve) => {
      // Set scene dimensions
      this.sceneDimensions = SetSceneDimensions(this.camera);
      // Build Christs
      CreateChrists( christSrc, this.scene, resolve );
    });
  }

  setupLights = () => {
    return;    
  }

  moveCamera = ( render ) => {
    if (!this.keys.up && !this.keys.down && !this.autoMove) {
      return;
    }
    
    const z = 0.5;
    const { x, y } = this.sceneDimensions.offset;
    const moveAmount = {
      x: x * -(z / this.cameraInitial.z),
      y: y * -(z / this.cameraInitial.z),
      z: z
    };
    
    if (this.keys.up || this.autoMove ) {
      moveAmount.y = moveAmount.y * -1;
      moveAmount.z = moveAmount.z * -1;
    }

    if ( this.keys.down ) {
      moveAmount.x = moveAmount.x * -1;
    }

    this.camera.position.x += moveAmount.x;
    this.camera.position.y += moveAmount.y;
    this.camera.position.z += moveAmount.z;

    if (this.camera.position.z < 0) {
      this.resetCamera();
    }
    
    render();
  }

  resetCamera = () => {
    this.camera.position.x = 0;
    this.camera.position.y = 0;
    this.camera.position.z = this.cameraInitial.z + this.camera.position.z;
  }

  animate = () => {
    // Move camera
    this.moveCamera(() => {
      this.renderer.render(this.scene, this.camera);
    });
    // Start loop again
    requestAnimationFrame(this.animate);
  }

  render() {
    return (
      <div className="three-container" ref={element => this.containerElement = element}>
        <LoadingOverlay loaded={this.state.loaded} />
        <div className="three-scroller" ref={element => this.scrollElement = element} />
        <canvas id="three-canvas" ref={element => this.canvas = element} />
      </div>
    );
  }

  checkKey = ( e, keyDown ) => {
    if (e.keyCode == '38') {
      // up arrow
      this.keys.up = keyDown;
    }
    else if (e.keyCode == '40') {
      // down arrow
      this.keys.down = keyDown;
    }
    // console.log(this.keys);
  }

  _listenArrowKeys = () => {
    document.onkeydown = (e) => {
      this.checkKey( e, true );
    };
    document.onkeyup = (e) => {
      this.checkKey( e, false );
    };
  }

  _resize() {
    let { innerWidth: width, innerHeight: height } = window;
    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderer.render(this.scene, this.camera);
  }
}