const sceneDimensions = {
  imgRatio: 1,
  width: null,
  height: null,
  zGap: null,
  holeRatio: {
    x: 969 / 2048,
    y: 1108 / 2048
  },
  holePosition: {
    x: undefined,
    y: undefined
  },
  offset: {
    x: undefined,
    y: undefined,
    z: undefined,
  }
};

const CreateChrists = function ( src, scene, resolve ) {
  
  getChristMesh( src ).then(function( christ ){
    
    const totalChrists = 3;
    let c = 0;

    while ( c++ < totalChrists ) {
      let mesh = christ.clone();
      mesh.matrixAutoUpdate = false;
      positionChrist( mesh, c );
      scene.add( mesh );
    }
    
    resolve();

  });
};

function positionChrist( mesh, c ) {
  mesh.position.x = -sceneDimensions.offset.x * (c - 1);
  mesh.position.y = sceneDimensions.offset.y * (c - 1);
  mesh.position.z = sceneDimensions.offset.z * (c - 1);
  mesh.updateMatrix();
}

// CREATE AN IMAGE
function getChristMesh ( src ) {
  return new Promise(function ( resolve ) {


    // SETUP LOADER
    const loadingManager = new THREE.LoadingManager();
    const imageLoader = new THREE.TextureLoader(loadingManager);

    // GEOMETRY
    const geometry = new THREE.PlaneGeometry(
      sceneDimensions.width,
      sceneDimensions.height,
      4
    );
    // MATERIAL
    const imgTexture = imageLoader.load(src);
    // imgTexture.encoding = sRGBEncoding;
    const material = new THREE.MeshBasicMaterial({
      map: imgTexture,
      transparent: true,
      // color: 0x000000,
      side: THREE.FrontSide
    });

    // imgTexture.minFilter = imgTexture.magFilter = THREE.LinearFilter;
    // imgTexture.mapping = THREE.UVMapping;

    // CREATE MESH
    const mesh = new THREE.Mesh(geometry, material);

    loadingManager.onLoad = function () {
      resolve(mesh);
    };
  });
}

const SetSceneDimensions = function ( camera ) {
  const distance = camera.position.z;
  const vFov = camera.fov * Math.PI / 180;
  // Set image size
  const height = 2 * Math.tan(vFov / 2) * distance;
  sceneDimensions.height = height;
  sceneDimensions.width = height * sceneDimensions.imgRatio;
  // Set hole position
  // Set offset props
  sceneDimensions.holePosition = {
    x: sceneDimensions.width * sceneDimensions.holeRatio.x,
    y: sceneDimensions.height * sceneDimensions.holeRatio.y
  };
  
  // Set offset props
  sceneDimensions.offset = {
    x: sceneDimensions.width / 2 - sceneDimensions.holePosition.x,
    y: sceneDimensions.height / 2 - sceneDimensions.holePosition.y,
    z: -camera.position.z
  };
  
  return sceneDimensions;
};

export { CreateChrists, SetSceneDimensions };